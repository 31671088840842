const ArrowCircleUp = require("./arrow-circle-up.svg");
const ArrowCircleDown = require("./arrow-circle-down.svg");
const HeroLogo = require("./hero-logo.png");
const CloseIcon = require("./close.svg");
const RightMark = require("./right-mark.svg");
const AngleLeft = require("./angle-left.svg");
const AngleRight = require("./angle-right.svg");
const AngleDown = require("./angle-down.svg");
const Polygon = require("./polygon.svg");
const Plus = require("./plus.svg");
const Car = require("./car.svg");
const CarWhite = require("./car-white.svg");
const BathRoom = require("./bathroom.svg");
const BathRoomWhite = require("./bathroom-white.svg");
const Clint = require("./Clint.jpg");
const BedRoom = require("./bedroom.svg");
const BedRoomWhite = require("./bedroom-white.svg");
const AreaSize = require("./size.svg");
const AreaSizeWhite = require("./size-white.svg");
const Edit = require("./edit.svg");
const ThreeDot = require("./three-dot.svg");
const TrashBin = require("./trash-bin.svg");
const HelpHeader = require("./help-header.png");
const NoImage = require("./no_image.jpg");
const ArrowRight = require("./arrow-right.svg");
const NotFoundPage = require("./404.svg");
const Anz = require("./bank_logo/ANZ.png");
const Boq = require("./bank_logo/BOQ.png");
const CommonWealth = require("./bank_logo/CommonWealth.png");
const Mac = require("./bank_logo/MAC.png");
const Nab = require("./bank_logo/NAB.png");
const Westpac = require("./bank_logo/Westpac.png");
const MenuLeftBackground = require("./background.jpg");
const SharingPC = require("./sharing-pc.png");
const Privacy = require("./privacy.svg");
const Secure = require("./secure.svg");
const CrossDevice = require("./sharing-service/cross-device.png");
const Residential = require("./sharing-service/residential.svg");
const Commercial = require("./sharing-service/commercial.svg");
const Equipment = require("./sharing-service/equipment.svg");
const Medical = require("./sharing-service/medical.svg");
const PrivateFund = require("./sharing-service/private-fund.svg");
const PropertyAdvisory = require("./sharing-service/property-advisory.svg");
const MinhScreenshot = require("./minh-screenshot.jpg");
const IphoneMockup = require("./iphone-mock.png");
const StarBox = require("./star-box.svg");
const BlueObject = require("./blue-object.svg");
const DefaultBrokerImage = require("./default-broker-image.png");
const DefaultBottomBrokerImage = require("./default-bottom-broker-image.jpg");
// share and super
const AwareSuper = require("./shares-and-super-logos/AwareSuper.png");
const CbusSuper = require("./shares-and-super-logos/CbusSuper.png");
const Netwealth = require("./shares-and-super-logos/Netwealth.png");
const CommSec = require("./shares-and-super-logos/CommSec.png");
// awards image
const AwardsABAMortgage = require("./awards/ABA_2020_Winner_Commercial_broker_of_the_year.png");
const AwardsMortgage = require("./awards/mortgage-award-2020.png");
const AwardsTop252020 = require("./awards/top-25-2020.png");
const AwardsTop252021 = require("./awards/top-25-2021.png");
const WinnerSealFinance = require("./awards/Winner seal__NSW_Best Finance Broker.png");
const WinnerSealBroker = require("./awards/Winner seal__NSW_Broker of the Year.png");
const ABAFinalistNewCommer = require("./awards/ABA_2020-Finalist_Newcomer of the Year.png");
const ABAFinanlistCommercialBroker = require("./awards/ABA_2020-Finalist_Commercial Broker of the Year.png");
const ABAFinanlistCommercialBroker2021 = require("./awards/ABA_2021-Finalist__COMMERCIAL BROKER OF THE YEAR.png");
const ABAFinanlistCustomerService = require("./awards/ABA_2020-Finalist_Customer Service of the Year.png");
const ABAFinanlistOfficeOfTheYear = require("./awards/ABA_2021-Finalist__OFFICE OF THE YEAR – NON-FRANCHISE.png");
const FinalistSealBestCustomer = require("./awards/finalist seal_NSW_Best Customer Service.png");
const FinalistSealBestfinance = require("./awards/finalist seal_NSW_Best Finance Broker.png");
const FinanlistSealBestIndupendent = require("./awards/finalist seal_NSW_Best Independent Office.png");
const AMAFinalistBrokerOfTheYear = require("./awards/AMA20 Finalist Medal_Broker of the Year - Commercial.png");
const AMAFinalistBrokerOfTheYearIndependent = require("./awards/AMA20 Finalist Medal_Broker of the Year - Independent.png");
const AMAFinalistBrokerageOfTheYear = require("./awards/AMA20 Finalist Medal_Brokerage of the Year - 6-20 Staff.png");
const AMAFinalistBrokerageOfTheYearDiversification = require("./awards/AMA20 Finalist Medal_Brokerage of the Year - Diversification.png");
const AMAFinalistNewBrokerage = require("./awards/AMA20 Finalist Medal_New Brokerage of the Year.png");

const ArrowUp = require("./arrow-up.svg");
const ArrowDown = require("./arrow-down.svg");

export {
  Clint,
  ArrowUp,
  ArrowDown,
  AMAFinalistBrokerageOfTheYear,
  AMAFinalistNewBrokerage,
  AMAFinalistBrokerageOfTheYearDiversification,
  AMAFinalistBrokerOfTheYearIndependent,
  AMAFinalistBrokerOfTheYear,
  FinalistSealBestCustomer,
  FinalistSealBestfinance,
  FinanlistSealBestIndupendent,
  WinnerSealFinance,
  ABAFinalistNewCommer,
  ABAFinanlistCommercialBroker,
  ABAFinanlistCommercialBroker2021,
  ABAFinanlistCustomerService,
  ABAFinanlistOfficeOfTheYear,
  WinnerSealBroker,
  DefaultBrokerImage,
  DefaultBottomBrokerImage,
  BlueObject,
  StarBox,
  IphoneMockup,
  MinhScreenshot,
  Residential,
  Commercial,
  Equipment,
  Medical,
  PrivateFund,
  PropertyAdvisory,
  CrossDevice,
  AwardsABAMortgage,
  AwardsMortgage,
  AwardsTop252020,
  AwardsTop252021,
  Privacy,
  Secure,
  SharingPC,
  MenuLeftBackground,
  Anz,
  Boq,
  CommonWealth,
  Mac,
  Nab,
  Westpac,
  AwareSuper,
  CbusSuper,
  Netwealth,
  CommSec,
  NotFoundPage,
  ArrowRight,
  ArrowCircleUp,
  ArrowCircleDown,
  HeroLogo,
  Car,
  CarWhite,
  CloseIcon,
  RightMark,
  AngleDown,
  AngleLeft,
  AngleRight,
  AreaSize,
  AreaSizeWhite,
  BathRoom,
  BathRoomWhite,
  BedRoom,
  BedRoomWhite,
  ThreeDot,
  TrashBin,
  HelpHeader,
  Edit,
  NoImage,
  Plus,
  Polygon,
};
